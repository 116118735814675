enum FeatureFlags {
  DUAL_ENVIRONMENTS_FLAG = 'DUAL_ENVIRONMENTS_FLAG',
  ORG_ASYNC_REQUESTS_ENABLED = 'ORG_ASYNC_REQUESTS_ENABLED',
  ORG_USE_TRAFFIC_RAMP_UP_WHILE_PROMOTING = 'ORG_USE_TRAFFIC_RAMP_UP_WHILE_PROMOTING',
  ORG_CHAIN_UI_ENABLED = 'ORG_CHAIN_UI_ENABLED',
  ORG_ENABLE_ACTIVITY_FEED = 'ORG_ENABLE_ACTIVITY_FEED',
  ORG_ENABLE_TRAFFIC_RAMP_UP_FOR_CHAINS_PROMOTION = 'ORG_ENABLE_TRAFFIC_RAMP_UP_FOR_CHAINS_PROMOTION',
  ORG_ENABLE_CUSTOMIZABLE_METRICS = 'ORG_ENABLE_CUSTOMIZABLE_METRICS',
}

export { FeatureFlags };
