import { useEffect } from 'react';

import { Route, Switch, useHistory } from 'react-router-dom';

import { Login, Register } from '@/pages/Auth';
import asyncComponentLoader from '@/utils/react/loader';

import AuthedApp from './AuthedApp';
import { BasetenPageEnum, operatorViewRoute, renderRoutes } from './routes';
import { trackPageView } from './utils/analytics/google';

// We need to delay the tracking of the page view to give React Helmet time to initialize
// See https://github.com/nfl/react-helmet/issues/189
const delayedPageTrack = () =>
  setTimeout(() => {
    trackPageView();
  }, 3000);

function Routes() {
  const history = useHistory();

  // Implementation taken from:
  // https://www.sheshbabu.com/posts/automatic-pageview-tracking-using-react-router/
  useEffect(() => {
    delayedPageTrack();
    history.listen(delayedPageTrack);
  }, [history]);

  return (
    <Switch>
      <Route path="/login/" component={Login} />
      <Route
        path="/signup/:inviteId/"
        component={asyncComponentLoader(() => import('@/pages/Onboarding'))}
      />
      <Route path="/signup/" component={Register} />
      {renderRoutes([operatorViewRoute], {
        [BasetenPageEnum.ViewOperator]: asyncComponentLoader(
          () => import('@/pages/Application/View/Operator'),
        ),
      })}
      <Route
        path="/waiting_room"
        component={asyncComponentLoader(() => import('@/pages/Auth/WaitingRoom'))}
      />
      <Route component={AuthedApp} />
    </Switch>
  );
}

export default Routes;
