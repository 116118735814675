import useIsHijacked from '@/hooks/useIsHijacked';
import useUser from '@/hooks/useUser';
import BillBar from '@/sections/Billip/BillBar/lazy';

import Left from './Left';
import Right from './Right';
import { Header } from './styled';
import { GlobalHeaderProps } from './types';

const GlobalHeader = ({
  logoStyle = 'icon',
  padding = 'default',
  page,
  breadcrumbs,
}: GlobalHeaderProps) => {
  const user = useUser();
  const showSuperuserUI = useIsHijacked() || user.showAdminUI;

  return (
    <>
      {showSuperuserUI && <BillBar />}
      <Header padding={padding}>
        <Left logoStyle={logoStyle} breadcrumbs={breadcrumbs} page={page} />
        <Right />
      </Header>
    </>
  );
};

export default GlobalHeader;
