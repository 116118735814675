import { useEffect } from 'react';

import { useAsOperator } from '@/hooks';
import CreatorApp from '@/pages/Creator';
import OperatorApp from '@/pages/Operator';

import { useFeatureFlagsLoading } from './hooks/useFeatureFlags';
import { useOrgLoading } from './hooks/useOrg';
import { useUserLoading } from './hooks/useUser';
import { useSentryIdentify } from './utils/analytics/hooks';
import { enableSegment } from './utils/analytics/segment/singleton';

function AuthedApp() {
  const asOperator = useAsOperator();
  const { loading: loadingUser } = useUserLoading();
  const { loading: loadingOrg } = useOrgLoading();
  const { loading: featureFlagsLoading } = useFeatureFlagsLoading();
  useSentryIdentify();

  useEffect(() => {
    enableSegment();
  }, []);

  if (loadingUser || loadingOrg || featureFlagsLoading) return null;

  if (asOperator) return <OperatorApp />;

  return <CreatorApp />;
}

export default AuthedApp;
