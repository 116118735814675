import * as React from 'react';
import { useState } from 'react';

import { Divider, IconButton, Stack, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';

import KeyIcon from '@/components/Icons/KeyIcon';
import LogOutIcon from '@/components/Icons/LogOutIcon';
import SettingsIcon from '@/components/Icons/SettingsIcon';
import UserIcon from '@/components/Icons/UserIcon';
import { useAsOperator } from '@/hooks';
import useUser from '@/hooks/useUser';
import { Tabs } from '@/pages/Settings/Tabs/types';
import { BasetenPageEnum } from '@/routes';

import { AppLink, MenuItem } from '../../../styled';

function UserMenu() {
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isAccountMenuOpen = Boolean(accountMenuAnchorEl);
  const user = useUser();
  const { firstName, lastName, email } = user;
  const asOperator = useAsOperator();

  function openAccountSettings(event: React.MouseEvent<HTMLElement>): void {
    setAccountMenuAnchorEl(event.currentTarget);
  }

  function handleCloseAccountMenu(): void {
    setAccountMenuAnchorEl(null);
  }

  function logoutUser() {
    window.open('/logout', '_self');
  }

  return (
    <>
      <IconButton
        data-cy="global-header-user-settings"
        onClick={openAccountSettings}
        aria-controls={isAccountMenuOpen ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isAccountMenuOpen ? 'true' : undefined}
      >
        <UserIcon />
      </IconButton>
      <Menu
        anchorEl={accountMenuAnchorEl}
        open={isAccountMenuOpen}
        onClose={handleCloseAccountMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack sx={{ p: 1, minWidth: 180 }}>
          <Typography variant="h5">
            {firstName} {lastName}
          </Typography>
          <Typography color="secondary" variant="body2" data-cy="user-menu-email">
            {email}
          </Typography>
        </Stack>
        {!asOperator && [
          <MenuItem key="api-keys">
            <AppLink
              onClick={handleCloseAccountMenu}
              page={BasetenPageEnum.Settings}
              params={{ tabId: Tabs.ApiKeys }}
            >
              <KeyIcon color="secondary" /> API keys
            </AppLink>
          </MenuItem>,
          <MenuItem key="profile">
            <AppLink
              onClick={handleCloseAccountMenu}
              page={BasetenPageEnum.Settings}
              params={{ tabId: Tabs.General }}
            >
              <SettingsIcon color="secondary" /> Settings
            </AppLink>
          </MenuItem>,
        ]}

        <Divider sx={{ mx: -1, my: 1 }} />
        <MenuItem sx={{ py: 0.75, px: 1 }} onClick={logoutUser}>
          <LogOutIcon color="secondary" /> Log out
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;
