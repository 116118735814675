/* THIS FILE IS AUTO-GENERATED! DO NOT MODIFY!! */
/* eslint-disable */

import type * as Types from '@/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MonetaryCreditsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MonetaryCreditsQuery = { __typename?: 'QueryPrediction', organization: { __typename?: 'OrganizationType', id: string, creditGranted: number | null | undefined, creditBalance: number | null | undefined, paymentMethodStatus: Types.PaymentMethodStatus | null | undefined } | null | undefined };


export const MonetaryCreditsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MonetaryCredits"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","alias":{"kind":"Name","value":"creditGranted"},"name":{"kind":"Name","value":"monetary_credit_granted"}},{"kind":"Field","alias":{"kind":"Name","value":"creditBalance"},"name":{"kind":"Name","value":"monetary_credit_balance"}},{"kind":"Field","alias":{"kind":"Name","value":"paymentMethodStatus"},"name":{"kind":"Name","value":"payment_method_status"}}]}}]}}]} as unknown as DocumentNode<MonetaryCreditsQuery, MonetaryCreditsQueryVariables>;
export const namedOperations = {
  Query: {
    MonetaryCredits: 'MonetaryCredits'
  }
}

/**
 * __useMonetaryCreditsQuery__
 *
 * To run a query within a React component, call `useMonetaryCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonetaryCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonetaryCreditsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMonetaryCreditsQuery(baseOptions?: Apollo.QueryHookOptions<MonetaryCreditsQuery, MonetaryCreditsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonetaryCreditsQuery, MonetaryCreditsQueryVariables>(MonetaryCreditsDocument, options);
      }
export function useMonetaryCreditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonetaryCreditsQuery, MonetaryCreditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonetaryCreditsQuery, MonetaryCreditsQueryVariables>(MonetaryCreditsDocument, options);
        }
export type MonetaryCreditsQueryHookResult = ReturnType<typeof useMonetaryCreditsQuery>;
export type MonetaryCreditsLazyQueryHookResult = ReturnType<typeof useMonetaryCreditsLazyQuery>;
export type MonetaryCreditsQueryResult = Apollo.QueryResult<MonetaryCreditsQuery, MonetaryCreditsQueryVariables>;
export function refetchMonetaryCreditsQuery(variables?: MonetaryCreditsQueryVariables) {
      return { query: MonetaryCreditsDocument, variables: variables }
    }